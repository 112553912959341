import React from 'react';
//import logo from '../images/logo.png';
import Banner from './Banner/Banner';


function Home() {
    return (
        <div>
            <Banner/>
            <div className="text-center text-white">
                Home page
            </div>
        </div>
    )
}
export default Home;